.footer-logo {

  @include from($break_lg) {
    margin: 0 0 0 auto !important;
  }
  display: flex !important;
  width: em(143);
  height: em(33);
  margin: 0 auto;
  transition: .3s all;
  background: #657396;
  -webkit-mask-image: url("../../images/logo-footer.svg");
  mask-image: url("../../images/logo-footer.svg");

  &:hover {
    background: #abb7d7;
  }
}

.footer-main {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;

  &__container {
    @include container();
    padding-bottom: em(16);
  }

  &__row {
    @include row-flex();
    justify-content: center;
  }

  &__col {
    @include col();
    @include size-lg(12);
    @include size(6);
  }

  &__payment-icon {
    width: 2rem;
    height: 3.25rem;
  }
}

.footer-nav {
  $footerNavRoot: &;

  &__items {

    @include from($break_lg) {
      gap: 16px;
      flex-flow: row wrap;
      justify-content: center;
    }

    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
  }

  &__item {
    @include from($break_lg) {
      text-align: initial;
    }
    display: inline-flex;
    margin-top: em(16);
    text-align: center;

    &_user-agreement {
      @include from($break_lg) {
        margin-left: em(36);
      }
    }
  }

  &__link {
    font-style: normal;
    color: #657396;
    transition: .3s all;
    cursor: pointer;

    &:hover {
      color: #abb7d7;
    }
  }
}

.footer-social {

  &__items {

    @include from($break_lg) {
      justify-content: flex-start;
    }
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
  }

  &__item {
    margin-top: em(16);
  }

  &__item:nth-of-type(2) {
    margin: em(16) em(10) 0 em(10);
  }

  &__link {

    i {
      width: em(23);
      height: em(23);
      background: #657396;
      transition: .3s all;

      &:hover {
        background: #abb7d7;
      }
    }
  }
}
