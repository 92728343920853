@import "../../../../assets/css/vendor/smart-grid";
@import "../../../../assets/css/helpers/functions";

.guest-donate {
  @include row-flex();
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  margin-bottom: em(120);

  &__form {
    max-width: rem(547);
    margin: 0 auto;
  }

  &__col {
    @include col();
    @include size(12);

    &_services {
      max-width: rem(547);
      margin-top: em(155);
    }
  }
}

.guest-donate__success {
  margin-top: rem(34);
  font-weight: 400;
  font-size: rem(16);
  line-height: rem(19);
  color: #FFC700;
  text-align: center;
}

.guest-donate__type {
  padding: rem(17) rem(33) rem(17) rem(24);

  @include from($break_lg) {
    background: url('../../../../assets/images/donate-item_right.svg') no-repeat top center / cover !important;
  }

  &:first-of-type {
    .guest-donate__types-name {
      color: #00A3FF;
    }
  }

  &:nth-of-type(2) {
    .guest-donate__types-name {
      color: #62DF00;
    }
  }

  &:nth-of-type(3) {
    .guest-donate__types-name {
      color: #8145FF;
    }
  }

  &:nth-of-type(4) {
    .guest-donate__types-name {
      color: #FF7A00;
    }
  }
}

.guest-donate__types {

  &-title {
    @include from($break_xl) {
      text-align: left;
    }
  }

  &-name {
    text-transform: uppercase;
    font-weight: 900;
    font-size: rem(30);
    line-height: rem(36);
    color: black;
  }
}
