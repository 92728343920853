.page-lines {
  position: fixed;
  z-index: -1;
  top: 0;
  left: 50%;
  width: 1920px;
  height: 100%;
  transform: translateX(-50%);
  font-size: 0;
  pointer-events: none;

  &__line {
    position: absolute;
    bottom: 0;
    width: 1px;
    height: 100%;
    background: rgba(81, 101, 124, 0.24);

    &_1 {
      left: 216px;
      animation: 1s animateLine ease-in-out forwards infinite alternate;
    }

    &_2 {
      left: 653px;
      animation: 3s animateLine ease-in-out forwards infinite alternate;
    }

    &_3 {
      left: 829px;
      animation: 2s animateLine ease-in-out forwards infinite alternate;
    }

    &_4 {
      left: 1120px;
      animation: 4s animateLine ease-in-out forwards infinite alternate;
    }

    &_5 {
      left: 1524px;
      animation: 1s animateLine ease-in-out forwards infinite alternate;
    }

    &_6 {
      left: 1821px;
      animation: 3s animateLine ease-in-out forwards infinite alternate;
    }
  }
}
