@import "../../../assets/css/vendor/smart-grid";
@import "../../../assets/css/helpers/functions";

.title {
  @include from($break_md) {
    font-size: rem(50);
  }
  font-weight: 900;
  font-size: rem(36);
  line-height: rem(60);
  text-align: left;
  text-transform: uppercase;
  $titleRoot: &;

  &_gradient {
    background: linear-gradient(88.53deg, #fec802 -0.83%, #fd8307 74.43%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &_bg {
    position: relative;

    &::before {
      content: attr(data-title);
      position: absolute;
      top: -100%;
      left: 0;
      font-weight: 900;
      font-size: rem(120);
      line-height: rem(144);
      color: transparent;
      -webkit-text-stroke-width: 2px;
      -webkit-text-stroke-color: rgba(255, 255, 255, 0.05);
      white-space: nowrap;
    }
  }

  &_centered {
    text-align: center;

    .title_bg {

      &::before {
        left: 50% !important;
        transform: translateX(-50%) !important;
      }
    }
  }

  cursor: default;
}
