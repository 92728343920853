.user-pending {
  width: 100%;
  max-width: 500px;
  height: 500px;
  margin: 32px auto 0 auto;
  text-align: center;

  span {
    margin-top: 32px;
    font-weight: 400;
    font-size: 24px;
    color: #FFF;
  }
}
