@import "../../../../assets/css/helpers/functions";

.state-pending {
  margin: em(32) auto 0 auto;
  text-align: center;

  &__container {
    font-size: rem(24);
    color: #FFF;
  }
}
