.server-roles {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding-left: 24px;
}

.server-role {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  p {
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #FFF;
  }

  &__level {
    position: relative;
    margin-right: 8px;
    width: 30px;
    height: 24px;
    background: url('../../../../assets/images/icons/donate-pattern.svg') no-repeat center center / 100%;

    span {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      color: #FFF;
    }
  }
}

.server-role + .server-role {
  margin-top: 8px;
}
