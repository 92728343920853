@import "../../../assets/css/vendor/smart-grid";
@import "../../../assets/css/helpers/functions";


.donate-card {
  @include from(895px) {
    max-width: rem(407);
  }
  position: relative;
  width: 100%;
  height: auto;
  min-height: rem(484);
  background: rgba(189, 203, 240, 0.1);
  backdrop-filter: blur(39px);

  &__back {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    padding: em(16) 0 0 em(16);
    cursor: pointer;
    transition: .5s all;

    &:hover {
      opacity: 0.5;
    }
  }

  &__root {
    position: relative;
    height: rem(484);
    padding-top: rem(33);
  }

  &__role {
    width: rem(116);
    height: rem(116);
    margin: 0 auto;
    background: url('/assets/images/role.png') no-repeat top center / cover;
    filter: drop-shadow(0px 15px 88px rgba(149, 198, 255, 0.26));    }

  &__name {
    margin-top: rem(15);
    font-weight: 500;
    font-size: rem(18);
    line-height: rem(22);
    text-align: center;
    color: #9DB3D3;
  }

  &__about {
    margin-top: rem(9);
    font-weight: 300;
    font-size: rem(15);
    line-height: rem(18);
    text-align: center;
    color: #8B9AC1;
  }

  &__container {
    display: flex;
    flex-flow: column nowrap;
    margin: rem(53) auto 0 auto;
    width: 100%;
    max-width: rem(310);
  }

  &__item {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: rem(20);

    & > * {
      display: flex;
      flex: 1;
    }
  }

  &__type {
    font-weight: 300;
    font-size: rem(15);
    line-height: rem(18);
    color: #97ABDF;
    justify-content: flex-start;
  }

  &__value {
    font-weight: 500;
    font-size: rem(15);
    line-height: rem(18);
    color: #fff;
    justify-content: flex-end;
  }

  &__sum {
    display: block;
    height: rem(76);
    position: absolute;
    bottom: 0;
    width: 100%;
    background: rgba(120, 255, 117, 0.18);
    backdrop-filter: blur(39px);
    text-align: center;

    p {
      font-weight: 300;
      font-size: rem(16);
      line-height: rem(22);
      padding: rem(26) 0;
      color: #ABFFA9;

      strong {
        font-weight: 700;
      }
    }

    &_code {
      border: initial;
      cursor: pointer;
      transition: .3s background;

      p {
        font-weight: 700;
        font-size: rem(18);
        color: #ABFFA9;
        pointer-events: none;
      }

      &:hover {
        background: rgba(120, 255, 117, 0.28);
      }
    }
  }

  &__label {
    margin-top: rem(19);
    font-weight: 400;
    font-size: rem(16);
    color: #8B9AC1;

    &_errored {
      color: indianred;
    }
  }

  &__input {
    margin-top: rem(8);
    width: 100%;
    padding: 1.125em 1em 1.125em 1.5em;
    background: #222938;
    border: initial;
    color: #FFF;
  }
}
