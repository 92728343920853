@import "../../../../assets/css/vendor/smart-grid";
@import "../../../../assets/css/helpers/functions";

.donate-service {
  position: relative;
  margin-top: rem(17);
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  padding: rem(23) rem(25) rem(18) rem(30);
  background: rgba(189, 203, 240, 0.1);
  backdrop-filter: blur(39px);
  text-align: center;

  @include from($break_xl) {
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  @include from($break_lg) {
    background: url('/assets/images/donate-item.svg') no-repeat top center / cover;
  }

  &__name {
    @include from($break_xl) {
      margin-top: initial;
    }
    font-weight: 400;
    font-size: rem(20);
    line-height: rem(24);
    color: #FFF;
  }

  &__cost {
    @include from($break_xl) {
      margin-top: initial;
    }
    margin-top: rem(16);
    font-weight: 700;
    font-size: rem(24);
    line-height: rem(29);
    color: #FFB800;
  }
}
