@import '../../../assets/css/helpers/functions';

.scroll-top {
  position: fixed;
  z-index: 15;
  bottom: em(33);
  left: em(43);
  width: em(125);
  height: em(40);
  background: linear-gradient(90deg, #FF7A00 0%, #FF4D00 100%);
  box-shadow: 0px -2px 43px rgba(1, 0, 70, 0.25);
  border-radius: 2px;
  cursor: pointer;

  &__container {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    padding: em(13) em(16);

    span {
      font-weight: 500;
      font-size: rem(14);
      line-height: rem(17);
      text-transform: uppercase;
      color: #FFF;
    }
  }

  &__arrow {
    width: rem(13);
    height: rem(13);
    margin-right: rem(14);
  }
}
