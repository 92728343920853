@import '../../../../assets/css/vendor/smart-grid';
@import '../../../../assets/css/helpers/functions';

.user-donate {
  margin: em(60) 0 em(250) 0;
  @include row-flex();

  &__col {
    @include col();
    @include size(12);
    margin-bottom: em(51);

    &_buns {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-around;
      margin-top: rem(64);
    }
  }
}

.donate-block {

  &__title {
    @include from($break_xl) {
      text-align: right;
    }
    &-reverse {
      margin-bottom: rem(12);
      @include from($break_xl) {
        text-align: left !important;
      }
    }
    text-align: center;
    font-weight: 700;
    font-size: rem(23);
    line-height: rem(28);
    text-transform: uppercase;
    margin-bottom: rem(2);
  }
}

.donate-item {
  position: relative;
  margin-top: rem(17);
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  background: rgba(189, 203, 240, 0.1);
  backdrop-filter: blur(39px);
  text-align: center;

  @include from($break_xl) {
    flex-flow: row nowrap;
    justify-content: space-around;
  }

  @include from($break_lg) {
    background: url('../../../../assets/images/donate-item.svg') no-repeat top center / cover;
  }

  &__name {
    padding: rem(23) 0 rem(19) 0;
    font-weight: 400;
    font-size: rem(20);
    line-height: rem(24);
    color: #FFF;
  }

  &__icon {
    position: absolute;
    right: rem(24);
    top: 50%;
    transform: translateY(-50%);
    align-items: center;
    justify-content: center;
    display: none;

    @include from($break_xl) {
      display: inline-flex;
    }
  }

}

.role-item {
  @include from(1023px) {
  }
  margin-left: rem(59);
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin-bottom: em(21);
  background: #313B52;
  padding-left: rem(78);
  padding-top: rem(15);
  padding-bottom: rem(15);
  $roleItemRoot: &;

  &:nth-of-type(1) {

    #{$roleItemRoot}__pic {
      background: url('../../../../assets/images/roles/1.png') no-repeat top center / 100%;
    }
  }

  &:nth-of-type(2) {

    #{$roleItemRoot}__pic {
      background: url('../../../../assets/images/roles/1.png') no-repeat top center / 100%;
    }
  }

  &:nth-of-type(3) {

    #{$roleItemRoot}__pic {
      background: url('../../../../assets/images/roles/2.png') no-repeat top center / 100%;
    }
  }

  &:nth-of-type(4) {

    #{$roleItemRoot}__pic {
      background: url('../../../../assets/images/roles/3.png') no-repeat top center / 100%;
    }
  }

  &__pic {
    position: absolute;
    left: rem(-58);
    top: 50%;
    transform: translateY(-50%);
    width: rem(116);
    height: rem(116);
    margin-right: em(33);
    filter: drop-shadow(0px 15px 88px rgba(149, 198, 255, 0.26));

  }

  &__container {

  }

  &__header {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
  }

  &__level {
    position: relative;
    margin-right: 8px;
    width: 39px;
    height: 39px;
    background: url('../../../../assets/images/icons/donate-pattern.svg') no-repeat center center / 100%;

    span {
      position: absolute;
      top: 40%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      color: #FFF;
    }
  }

  &__name {
    font-weight: 500;
    font-size: rem(16);
    line-height: rem(19);
    color: #FFF;
  }

  &__ll {
    margin-top: rem(3);
    font-weight: 400;
    font-size: rem(15);
    line-height: rem(18);
    color: #737A8C;
  }

  &__dt {
    margin-top: rem(5);
    font-weight: 500;
    font-size: rem(16);
    line-height: rem(19);
    color: #FFF;
  }
}

.udonate-root {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  width: 100%;
  max-width: rem(992);
  margin: 0 auto;

  &__form {
    @include from(1023px) {
      max-width: rem(547);
      margin-right: rem(59);
    }
    width: 100%;
    margin-right: initial;
  }

  &__roles {
    @include from(1023px) {
      max-width: rem(370);
    }
    width: 100%;
  }
}

.user-donate__services {
  width: 100%;
  max-width: rem(535);
  margin-top: initial;
  margin-bottom: rem(36);
}

.udonate-root__balance {
  display: inline-flex;
  padding: rem(15) rem(14);
  background: linear-gradient(90deg, #FFC600 0%, #FF4D00 100%);
  box-shadow: 1px 20px 47px rgba(255, 77, 0, 0.46);
  border-radius: 2px;
  margin-bottom: rem(30);
  text-transform: uppercase;
  font-weight: 400;
  font-size: rem(17);
  line-height: rem(20);
  color: #fff;

  strong {
    font-weight: 700;
    margin-left: rem(5);
  }
}

.packs {
  margin-top: rem(-15);
  display: inline-flex;
  flex-flow: column nowrap;

  &-root {
    overflow: auto hidden;
  }

  &__col {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin-bottom: rem(6);

    &_thead {
      .pack {

        &__typed {
          font-weight: 900;
          font-size: rem(25);
          line-height: rem(30);
        }
        &__start {
          color: #00A3FF;
        }
        &__pro {
          color: #62DF00;
        }
        &__authority {
          color: #9745FF;
        }
        &__boss {
          color: #FF7A00;
        }
      }
    }

    &_invisible {
      .packs__row {
        background: initial;
      }
    }

    &_disabled {

      .packs__row {
        font-weight: 400;
        background: #4F586F;
        color: #657396;
        background: rgba(79, 88, 111, 0.1);
        backdrop-filter: blur(39px);
      }
    }

    &_margin {
      margin-top: rem(16);
    }
  }

  &__row {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    margin-right: rem(16);
    width: rem(200);
    min-height: rem(56);
    background: rgba(189, 203, 240, 0.1);
    backdrop-filter: blur(39px);
    text-align: center;
    font-weight: 500;
    font-size: rem(20);
    line-height: rem(24);
    text-transform: uppercase;
    color: #fff;

    &:last-of-type {
      margin-right: initial;
    }

    &_large {
      width: rem(262);
    }
  }
}

.packs__action {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: rem(17) 0;
  border: initial;
  font-weight: 700;
  font-size: rem(30);
  line-height: rem(36);
  color: #fff;
  border-radius: 2px;
  cursor: pointer;

  &_blue {
    background: linear-gradient(90deg, #00D1FF 0%, #0047FF 100%);
    box-shadow: 0px -2px 43px rgba(1, 0, 70, 0.25);
  }

  &_yellow {
    background: linear-gradient(90deg, #00AB26 0%, #BDFF00 100%);
    box-shadow: 0px -2px 43px rgba(1, 0, 70, 0.25);
  }

  &_purple {
    background: linear-gradient(90deg, #3300FF 0%, #8F00FF 100%);
    box-shadow: 0px -2px 43px rgba(1, 0, 70, 0.25);
  }

  &_orange {
    background: linear-gradient(90deg, #FFE600 0%, #FF4D00 100%);
    box-shadow: 0px -2px 43px rgba(1, 0, 70, 0.25);
  }
}

.udi__container {
  margin-top: 2.3125rem !important;
}

.packs__bonus {
  margin-top: rem(27);
  font-weight: 500;
  font-size: rem(18);
  line-height: rem(22);
  color: #fff;
}
