@import "../../../../assets/css/vendor/smart-grid";
@import "../../../../assets/css/helpers/functions";


.servers {
  @include row-flex();
  justify-content: center;
  @include from(1920px) {
    justify-content: space-between;
  }

  &__item {
    @include col();
    @include from(1600px) {
      max-width: em(300);
      margin-top: em(95);
    }
    @include size(3);
    @include size-xl(4);
    @include size-lg(4);
    @include size-md(6);
    @include size-sm(12);
    @include size-xs(12);
    margin-top: em(46);
  }

  &__item {

    &:nth-of-type(2) {

      .server__btn {
        background: linear-gradient(90deg, #EE008F 0%, #FF0000 100%);
        box-shadow: 0px -2px 43px rgba(1, 0, 70, 0.25);
      }
    }

    &:nth-of-type(3) {
      .server__btn {
        background: linear-gradient(90deg, #00C2FF 0%, #0066FF 100%);
        box-shadow: 0px -2px 43px rgba(1, 0, 70, 0.25);
      }
    }

    &:nth-of-type(4) {
      .server__btn {
        background: linear-gradient(90deg, #8000FF 0%, #BD00FF 100%);
        box-shadow: 0px -2px 43px rgba(1, 0, 70, 0.25);
      }
    }

    &:nth-of-type(5) {
      .server__btn {
        background: linear-gradient(90deg, #FF4D00 0%, #FF0000 100%);
        box-shadow: 0px -2px 43px rgba(1, 0, 70, 0.25);
      }
    }

    &:nth-of-type(6) {
      .server__btn {
        background: linear-gradient(90deg, #FFC600 0%, #FF1569 100%);
        box-shadow: 0px -2px 43px rgba(1, 0, 70, 0.25);
      }
    }

    &:nth-of-type(7) {
      .server__btn {
        background: linear-gradient(90deg, #00EEB5 0%, #BD00FF 100%);
        box-shadow: 0px -2px 43px rgba(1, 0, 70, 0.25);
      }
    }

    &:nth-of-type(8) {
      .server__btn {
        background: linear-gradient(90deg, #8F00FF 0%, #4200FF 100%);
        box-shadow: 0px -2px 43px rgba(1, 0, 70, 0.25);
      }
    }

    &:nth-of-type(9) {
      .server__btn {
        background: linear-gradient(90deg, #B7D8FF 0%, #636CBA 100%);
        box-shadow: 0px -2px 43px rgba(1, 0, 70, 0.25);
      }
    }

    &:nth-of-type(10) {
      .server__btn {
        background: linear-gradient(90deg, #00FF57 0%, #008575 100%);
        box-shadow: 0px -2px 43px rgba(1, 0, 70, 0.25);
      }
    }

    &:nth-of-type(11) {
      .server__btn {
        background: linear-gradient(90deg, #14286F 0%, #234B98 100%);
        box-shadow: 0px -2px 43px rgba(1, 0, 70, 0.25);
      }
    }

    &:nth-of-type(12) {
      .server__btn {
        background: linear-gradient(90deg, #007BA2 0%, #1DE5FF 100%);
        box-shadow: 0px -2px 43px rgba(1, 0, 70, 0.25);
      }
    }

    &:nth-of-type(13) {
      .server__btn {
        background: linear-gradient(90deg, #51A200 0%, #C4FF1D 100%);
        box-shadow: 0px -2px 43px rgba(1, 0, 70, 0.25);
      }
    }

    &:nth-of-type(14) {
      .server__btn {
        background: linear-gradient(90deg, #81003E 0%, #FF00E5 100%);
        box-shadow: 0px -2px 43px rgba(1, 0, 70, 0.25);
      }
    }

    &:nth-of-type(15) {
      .server__btn {
        background: linear-gradient(90deg, #FF4E43 0%, #FF833D 100%);
        box-shadow: 0px -2px 43px rgba(1, 0, 70, 0.25);
      }
    }

    &:nth-of-type(16) {
      .server__btn {
        background: linear-gradient(90deg, #FF4493 0%, #FF96E2 100%);
        box-shadow: 0px -2px 43px rgba(1, 0, 70, 0.25);
      }
    }
  }
}
