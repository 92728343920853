.home-servers {

  &__loading {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;

    &-title {
      margin-top: rem(24);
      font-size: rem(30);
      line-height: rem(36);
      text-align: center;
      color: #FFF;
    }
  }

  &__container {
  }

  &__title {
    @include from($break_lg) {
      margin-bottom: em(34);
    }
    padding-top: rem(120);
    margin-top: rem(129);
    font-size: rem(60);
    line-height: rem(72);

    &::before {
      top: rem(0);
      left: 50%;
      transform: translateX(-50%);
      font-size: rem(180);
      line-height: rem(216);
    }
  }

  &__list {
    @include row-flex();
    justify-content: center;
    @include from(1920px) {
      justify-content: space-between;
    }
  }
}
