.play {
  @include from(443px) {
    margin-top: em(388);
  }
  padding-top: em(120);
  margin-top: em(121);

  &__title {
    font-size: rem(60);
    line-height: rem(72);
    text-align: center;

    @include from($break_lg) {
      text-align: left;
    }

    &::before {
      top: rem(-135);
      left: rem(-8);
      font-size: rem(200);
      line-height: rem(240);
    }
  }
}

.play-steps {
  @include row-flex();
  justify-content: center;
  @include from($break_xl) {
    justify-content: space-between;
  }
}

.play-step {
  @include col();
  @include size(4);
  @include size-xl(6);
  @include size-md(12);
  margin-top: em(60);
  $playStepRoot: &;

  @include from($break_xl) {
    max-width: em(500);
  }

  &__container {
    position: relative;
    mask-image: url('/assets/images/steps-bg.svg');
    mask-repeat: no-repeat;
    mask-size: cover;
    height: 587px;
    background: #2A3764;

    #{$playStepRoot}_sea & {
      background: #00536D;
    }

    #{$playStepRoot}_orange & {
      background: #FF7A00;
    }
  }

  &__bg {
    position: absolute;
    top: -45%;
    width: 100%;
    height: 100%;
    transform: skewY(335deg);
    background: #142032;
    transition: .5s all;
  }

  &__pic {
    position: absolute;
    z-index: 1;
    top: em(135);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .5s all;

    img {
      max-width: 100%;
      transition: .5s all;
    }
  }

  &:hover {
    #{$playStepRoot}__bg {
      top: -65%;
      transform: skewY(390deg);
    }

    #{$playStepRoot}__pic {
      top: em(70);

      img {
        transform: scale(0.9);
      }
    }
  }


  &__about {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: em(0) em(33) em(33) em(50);

    p {
      font-weight: 400;
      font-size: rem(27);
      line-height: rem(32);
      color: #FFF;
    }
  }

  &__btn {
    width: 100%;
    border: initial;
    font-weight: 700;
    font-size: rem(25);
    line-height: rem(30);
    text-align: center;
    text-transform: uppercase;
    padding: em(20);
    color: #FFF;
    cursor: pointer;

    #{$playStepRoot}_blue & {
      background: linear-gradient(90deg, #3F5795 0%, #2F3B63 100%);
      box-shadow: 0px -2px 43px rgba(1, 0, 70, 0.25);
    }

    #{$playStepRoot}_sea & {
      background: linear-gradient(90deg, #009BBD 0%, #009BBD 20.31%, #009BBD 26.04%, #094883 100%);
      box-shadow: 0px -2px 43px rgba(0, 59, 85, 0.25);
    }

    #{$playStepRoot}_orange & {
      background: linear-gradient(90deg, #FFB800 0%, #FF4D00 100%);
      box-shadow: 0px -2px 43px rgba(197, 24, 0, 0.25);
    }
  }
}
