@import "../../../../assets/css/helpers/functions";

.server-header {
  position: absolute;
  z-index: 1;
  top: -1.6em;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;

  &__number {
    font-size: rem(40);
    line-height: rem(48);
    font-weight: 700;
    margin-right: rem(16);
    color: transparent;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #FFF;
  }

  &__container {
    display: flex;
    flex-flow: column nowrap;
    margin-top: em(8);
  }

  &__name {
    text-transform: uppercase;
    font-weight: 900;
    font-size: rem(30);
    line-height: rem(36);
    color: #FFF;
  }
}
