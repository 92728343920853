@import "../../../assets/css/vendor/smart-grid";
@import "../../../assets/css/helpers/functions";

.donate-services {

  &__title {
    position: relative;
    text-align: center;
    font-weight: 700;
    font-size: rem(23);
    line-height: rem(28);
    text-transform: uppercase;
    margin-bottom: rem(2);

    &::before {
      content: 'SERVICES';
      position: absolute;
      top: -5.8rem;
      width: 75.75rem;
      height: 8.6875rem;
      text-transform: capitalize !important;
      font-size: rem(120);
      line-height: rem(144);
      font-weight: 900;
      left: 50%;
      transform: translateX(-50%);
      color: transparent;
      -webkit-text-stroke-width: 2px;
      -webkit-text-stroke-color: rgba(255, 255, 255, 0.05);
      white-space: nowrap;
    }
  }

  &-root {
    max-width: rem(547);
    margin: rem(152) auto 0 auto;
  }
}
