.login-accepted {
  margin-right: em(12);

  &__wrapper {
    margin-top: em(70);
    cursor: pointer;
    text-align: center;

    span {
      font-size: rem(20);
      line-height: rem(24);
      font-weight: 400;
      color: #FFF;
    }
  }
}
