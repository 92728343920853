.captcha {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: rgba(28, 28, 28, 0.77);
  backdrop-filter: blur(30px);

  &__title {
    font-size: 30px;
    line-height: rem(38);
    font-weight: 500;
    color: #FFFFFF;
    margin-bottom: 25px;
  }

  & > * {
    -webkit-app-region: no-drag;
  }
}

.captcha * {
  -webkit-app-region: no-drag;
}
