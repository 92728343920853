@import '../../../../assets/css/helpers/functions';

.payments {

  &-selected {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    &__container {
      height: 100%;
    }

    &__icon {

    }

    &__name {
    }
  }

  &-list {
    position: absolute;
    z-index: 10;
    bottom: 0;
    width: 100%;
    border-radius: 2px;
    transition: .3s all;
    pointer-events: none;
    opacity: 0;

    &_active {
      opacity: 1;
      pointer-events: all;
    }
  }

  &-type {
    position: relative;
    background: #272F42;

    &:last-of-type {
      border-radius: 0 0 2px 2px;
    }

    &__container {
      display: flex;
      align-items: center;
      cursor: pointer;
      padding: em(14) em(27);
      transition: .5s all;

      span {
        font-size: rem(22);
        line-height: rem(26);
        color: #FFF;
      }

      &:hover {
        background: #3D4862;
      }
    }

    &__icon {
      display: inline-flex;
      width: em(31);
      height: em(31);
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
      margin-right: rem(33);
    }
  }
}

.modal__container {
  z-index: 1;
}
