.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 500ms;
}

.fromLeft-enter {
  opacity: 0;
  transform: translateX(-10vw);
}
.fromLeft-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: all 500ms;
}
.fromLeft-exit {
  opacity: 1;
  transform: translateX(0);
}
.fromLeft-exit-active {
  opacity: 0;
  transition: all 500ms;
}

.fromTop-enter {
  opacity: 0;
  transform: translateY(-5vw);
}
.fromTop-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: all 250ms;
}
.fromTop-exit {
  opacity: 1;
  transform: translateY(0);
}
.fromTop-exit-active {
  opacity: 0;
  transition: all 250ms;
}
