@import '../../../../assets/css/helpers/functions';

.donate-game {
  margin-top: rem(131);

  .donate-confirm {
    //max-width: em(606 + 407);
  }

  .donate-confirm__payments {
    max-width: em(670);
    min-height: 29.875rem;
  }

  .donate-payments__container {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .donate-payments__activate, .getKey > button {
    font-size: 1.0625rem;
    line-height: 1.25rem;
    padding: 1rem;
  }
}
