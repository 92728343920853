.icon {
  display: inline-flex;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  transition: all .3s;

  &__donate {
    width: rem(38);
    height: rem(36);
    background: url("../../images/icons/donate.svg") no-repeat top center / 100%;
  }

  &__wallet {
    width: em(28);
    height: em(28);
    background: url("../../images/icons/wallet.svg") no-repeat top center / 100%;
    margin-right: rem(12);
  }

  &__arrow-right {
    width: em(24);
    height: em(24);
    background: url("../../images/icons/arrow-right.svg") no-repeat top center / 100%;
  }

  &__scroll-down {
    display: inline-flex;
    width: rem(41);
    height: rem(145);
    background: url("../../images/icons/scroll-down.svg") no-repeat top center / 100%;
  }

  &__user {
    width: rem(16);
    height: rem(16);
    background: url("../../images/icons/user.svg") no-repeat top center / 100%;
  }

  &__top-arrow {
    background: url('../../images/icons/top-arrow.svg') no-repeat top center / 100%;
  }

  &__footer {

    &-discord {
      mask-image: url("../../images/icons/social/footer/discord.svg");
    }

    &-vk {
      mask-image: url("../../images/icons/social/footer/vk.svg");
    }

    &-youtube {
      mask-image: url("../../images/icons/social/footer/youtube.svg");
    }
  }

  &__social {

    &_discord {
      mask-image: url("../../images/icons/social/discord.svg");
    }

    &_vk {
      mask-image: url("../../images/icons/social/vk.svg");
    }

    &_youtube {
      mask-image: url("../../images/icons/social/youtube.svg");
    }
  }

  &__dp {
    width: em(32);
    height: em(32);
    background: url("../../images/icons/donate/dp.svg") no-repeat center center / 100%;
  }

  &__crown {
    width: em(32);
    height: em(32);
    background: url("../../images/icons/donate/crown.svg") no-repeat center center / 100%;
  }

  &__changename {
    width: em(32);
    height: em(32);
    background: url("../../images/icons/donate/changename.svg") no-repeat center center / 100%;
  }

  &__appearance {
    width: em(32);
    height: em(32);
    background: url("../../images/icons/donate/appearance.svg") no-repeat center center / 100%;
  }

  &__unwarn {
    width: em(32);
    height: em(32);
    background: url("../../images/icons/donate/unwarn.svg") no-repeat center center / 100%;
  }

  &__close-modal {
    display: inline-flex;
    width: em(27);
    height: em(27);
    background: url("../../images/icons/close-modal.svg") no-repeat center center / 100%;
  }

  &__arrow-left {
    display: inline-flex;
    width: em(32);
    height: em(32);
    background: url("../../images/icons/arrow-left.svg") no-repeat center center / 100%;
  }
}
