@import '../../../assets/css/helpers/functions';

.systemPreloader {
  position: fixed;
  z-index: 15;
  bottom: em(-32);
  right: em(-32);
  width: rem(200);
  height: rem(200);
  pointer-events: none;
}
