@import "../../../assets/css/helpers/functions";

.server {
  position: relative;
  height: em(210);
  cursor: pointer;
  $serverRoot: &;

  &__roles {
    font-weight: 500;
    font-size: rem(18);
    line-height: rem(22);
    color: #FFF;
  }

  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border: initial;

    &:first-of-type {
      background: linear-gradient(90deg, #FFC600 0%, #FF4D00 100%);
      box-shadow: 0px -2px 43px rgba(1, 0, 70, 0.25);
    }

    &:nth-of-type(2) {
      background: linear-gradient(90deg, #EE008F 0%, #FF0000 100%);
      box-shadow: 0px -2px 43px rgba(1, 0, 70, 0.25);
    }

    font-weight: 700;
    font-size: rem(15);
    line-height: rem(18);
    text-transform: uppercase;
    color: #FFF;
  }

  &:nth-of-type(2) {

    #{$serverRoot}__btn & {
      background: linear-gradient(90deg, #EE008F 0%, #FF0000 100%);
      box-shadow: 0px -2px 43px rgba(1, 0, 70, 0.25);
    }
  }
}
